import { defineStore } from "pinia";
import { dafisApi } from "@/helpers/api/dafisApi";
import { Contact } from "@/models/contact";

export const useContactStore = defineStore("contactStore", {
  state: () => ({
    contactsState: []
  }),

  actions: {
    async loadContacts() {
      if (this.contactsState.length === 0) {
        return this.reloadContacts();
      }
    },

    async reloadContacts() {
      const response = await dafisApi().get("/contact");
      this.contactsState = response.data.map(contact => new Contact(contact));
    },

    async saveContact(contact) {
      await dafisApi().patch(`/contact/${contact.id}`, contact);

      const index = this.contactsState.findIndex(c => c.id === contact.id);
      this.contactsState[index] = contact;
      return contact;
    },

    reloadMinimumData() {
      this.reloadContacts();
    }
  },

  getters: {
    contacts(state) {
      return state.contactsState;
    },

    contactsOrderedByName(state) {
      return state.contactsState.sort((a, b) => a.name.localeCompare(b.name));
    },

    contact(state) {
      return id => state.contactsState.find(contact => contact.id === id);
    },

    activeContacts(state) {
      const activeContacts = state.contactsState.filter(contact => contact.isActive && !contact.isDeleted);
      activeContacts.sort((a, b) => a.name.localeCompare(b.name));
      return activeContacts;
    },

    filteredContacts() {
      return viewConfiguration => {
        // Filter on status
        return this.contacts.filter(
          contact =>
            (viewConfiguration.isActiveContactsVisible && contact.isActive) ||
            (viewConfiguration.isInactiveContactsVisible && !contact.isActive)
        );
      };
    },

    searchedContacts() {
      return viewConfiguration => {
        if (!viewConfiguration.searchTerm) {
          return this.filteredContacts(viewConfiguration);
        }

        const searchTerm = viewConfiguration.searchTerm.toLowerCase();

        return this.filteredContacts(viewConfiguration).filter(contact => {
          return contact.name.toLowerCase().includes(searchTerm);
        });
      };
    },

    sortedAndFilteredContacts() {
      return viewConfiguration => {
        if (viewConfiguration.sortProperty.indexOf(".") > -1) {
          const split = viewConfiguration.sortProperty.split(".");
          return [...this.searchedContacts(viewConfiguration)].sort((a, b) => {
            try {
              if (a[split[0]][split[1]] < b[split[0]][split[1]]) {
                return viewConfiguration.sortDirection === "asc" ? -1 : 1;
              }
              if (a[split[0]][split[1]] > b[split[0]][split[1]]) {
                return viewConfiguration.sortDirection === "asc" ? 1 : -1;
              }
              return 0 || a.createdDateTime > b.createdDateTime;
            } catch {
              return 0;
            }
          });
        } else {
          return [...this.searchedContacts(viewConfiguration)].sort((a, b) => {
            if (a[viewConfiguration.sortProperty] < b[viewConfiguration.sortProperty]) {
              return viewConfiguration.sortDirection === "asc" ? -1 : 1;
            }
            if (a[viewConfiguration.sortProperty] > b[viewConfiguration.sortProperty]) {
              return viewConfiguration.sortDirection === "asc" ? 1 : -1;
            }
            return 0 || a.createdDateTime > b.createdDateTime;
          });
        }
      };
    }
  }
});
