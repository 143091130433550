import { required, email } from "@vuelidate/validators";

export class User {
  organizationId = null;
  id = null;
  name = null;
  email = null;
  password = null;
  role = null;
  state = null;
  subscriptionType = null;
  reloginToken = null;

  constructor(dbRegistration) {
    if (dbRegistration) {
      this.organizationId = dbRegistration.organizationId;
      this.id = dbRegistration.id;
      this.name = dbRegistration.name;
      this.email = dbRegistration.email;
      this.password = dbRegistration.password;
      this.role = dbRegistration.role;
      this.state = dbRegistration.state;
      this.subscriptionType = dbRegistration.subscriptionType;
      this.reloginToken = dbRegistration.reloginToken;
    }
  }

  static parseUserFromAccessToken(parsedToken) {
    const user = new User();
    user.organizationId = parsedToken.aud;
    user.id = parsedToken.subId;
    user.email = parsedToken.sub;
    user.role = parsedToken.role;
    user.subscriptionType = parsedToken.license;
    user.reloginToken = parsedToken.reloginToken;

    return user;
  }

  get isActive() {
    return this.state === USER_STATE.INVITED ? null : this.state === USER_STATE.ACTIVE;
  }

  set isActive(value) {
    this.state = value ? USER_STATE.ACTIVE : USER_STATE.BANNED;
  }
}

export const UserValidationRules = Object.freeze({
  name: { required },
  email: { required, email },
  role: { required },
  state: { required }
});

export const SUBSCRIPTION_TYPE = Object.freeze({
  NONE: 0,
  TRYOUT: 1,
  FREE: 2,
  PRO: 4
});

export const USER_ROLE = Object.freeze({
  OWNER: 1,
  SUPERUSER: 2,
  USER: 4
});

export const USER_STATE = Object.freeze({
  INVITED: 1,
  ACTIVE: 2,
  BANNED: 4,
  DELETED: 8
});
